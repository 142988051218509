@use "./variables" as *

.App
  display: flex
  flex-direction: column
  align-items: center

  font-family: Helvetica, Sans-Serif
  color: white

  .app-body
    padding-top: 20px
    overflow-y: scroll
    width: 100vw
    display: flex
    justify-content: center


@media (prefers-reduced-motion: no-preference)
  .App-logo
    animation: App-logo-spin infinite 20s linear

button
  cursor: pointer


@keyframes App-logo-spin
  from
    transform: rotate(0deg)

  to
    transform: rotate(360deg)
