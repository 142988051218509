@use "./variables" as *

.app-header
  width: 100vw

  display: flex
  flex-direction: row
  justify-content: space-between
  align-content: center
  z-index: 1000000000
  background-color: $red1
  cursor: pointer

  & > *
    margin: 5px

  .logo
    cursor: pointer
    font-weight: 600
    margin-bottom: 0px

    img
      height: 40px

  .search
    min-width: 300px
    align-items: center
    display: flex

    [type=search]
      border-radius: 5px

  .app-header-right
    display: flex
    flex-direction: row
    align-items: center

.user-menu
  margin-top: 18px

  .ant-dropdown-menu
    background-color: black
