@use "./variables" as *

.player-page
  display: flex
  flex-direction: column
  color: white
  padding: 0 10px
  width: 100%
  max-width: 700px

  .player-page-header
    display: flex
    flex-direction: row
    flex-wrap: wrap

    & > *
      margin: 3px

    .user
      margin-right: auto
      font-size: 30px
      font-weight: 600

    .player-page-selects
      & > *
        margin: 3px

    .leave-comment-button
      background-color: $red1

.error-page
  margin-top: 200px
  font-size: 20px
  background-color: $red1
  padding: 20px
  width: 500px
  border-radius: 5px
  text-align: center
  line-height: 30px
