@use "./variables.sass" as *

.leave-comment-page
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 600px

  .leave-comment-header
    font-size: 18px
    margin-bottom: 10px

  .leave-comment-text-area
    height: 300px

  .submit-button
    margin-left: 10px
    width: 100px
    margin-top: 10px
    background-color: $red1
