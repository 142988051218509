@use "./variables" as *

.admin-create-user-page
  display: flex
  flex-direction: column
  align-items: center

  .header
    font-size: 20px

  > div
    flex-direction: row
    display: flex
    margin: 8px 0

    > div:first-child
      width: 100px

  > button:last-child
    margin-top: 10px

  .error
    color: $error-red