@use "./variables" as *

.login
  display: flex
  flex-direction: column
  align-items: center
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

  background-image: url('/resources/images/loginBackground.jpg')
  background-size: cover
  background-attachment: fixed

  .login-widget
    display: flex
    flex-direction: column
    align-items: center
    margin: auto

    background-color: rgba(14, 12, 23, 0.6)
    backdrop-filter: blur(40px)
    border-radius: 16px
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%)
    padding: 50px 80px

    color: #f1eeee

    .login-form
      display: flex
      flex-direction: column
      align-items: center
      width: 350px

    .error-prompt
      margin-top: 8px
      color: red
      display: none

    .error-prompt.is-invalid
      display: block

    .forgot-password
      color: #0000E5
      padding: 5px 0px
      cursor: pointer

    .new-account-button
      width: auto
      background-color: #561643
      color: $login-text
      margin-top: 20px

    .signInButton
      background-color: $red1 // rgba(150, 0, 24, 1)
      width: 100%


    .header
      display: flex
      align-items: center
      flex-direction: column
      margin-bottom: 18px
      font-family: Palatino, serif

      .logo
        width: 100px

      .title
        font-size: 30px
        font-weight: 700
        color: #f1eeee

      .subtitle
        color: #938d8d
        font-size: 14px
        padding: 3px

  .disclaimer
    width: 350px
    padding: 5px
    color: $gray2

.about-us
  margin-top: 10px
  color: $login-text
  cursor: pointer