$gray1: #f5f3f4
$gray2: #3f3f4f

$red1: #a40606
$red2: #f1a7a9
$error-red: #5c0000

$background-gray: #252323

// Login Page
$login-text: #E8F0FE
$login-border: black