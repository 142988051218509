@use "./variables.sass" as *

$commentFlagColor: #fa5711

.comment-card
  display: flex
  flex-direction: column
  margin: 10px 0px
  border-radius: 10px
  font-size: 15px

  .comment-flag-icon
    padding-right: 3px
    color: #fa5711
    font-size: 20px
    vertical-align: -2.9px

  .comment-flag
    margin-left: auto

  .comment-card-header
    display: flex
    flex-direction: row
    justify-content: space-between
    color: #a39e9e
    font-size: 12px
    margin-bottom: 5px



  .delete-comment-icon
    padding-right: 3px

  .comment-card-more-options:hover
    cursor: pointer